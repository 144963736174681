import Storage from "@/util/Storage";
import {AgainPhoneOpenId} from "@/Api/Basics";
import {Toast} from "vant";

// 重新绑定
export function AgainPhone(phone:number|string="",smsCode:number|string="",openId="") {
    const userId = Storage.GetData_?.("userId")
    const token = Storage.GetData_?.("token")
    Toast.loading({ duration:0,message:"换绑中~~" })
    return Promise.resolve(AgainPhoneOpenId({ userId,token },{  phone:phone as string,openId,smsCode:smsCode as string }).then(res=>{
        Toast.clear()
        Toast("换绑成功")
        return res.data
    }))
}
